/* global tw */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const PaginationHolder = styled.div`
  ${tw`flex relative justify-between -mt-4 p-0`};
`

const PaginationBlock = ({
  nextPage,
  prevPage,
  numPages,
  currentPage,
  isFirst,
  isLast,
  linkWord,
  ...props
}) => {
  return (
    <div className="container mb-12">
      <PaginationHolder>
        {!isFirst && (
          <Link
            to={prevPage}
            rel="prev"
            className="mr-auto bg-black font-graphik font-bold leading-normal text-sm uppercase tracking-wide text-white hover:bg-white hover:text-black trans p-4 z-10"
          >
            ← Previous Page
          </Link>
        )}

        <span className="text-grey opacity-75 absolute pin-l pin-r w-full text-center py-4 px-6 z-0">
          {currentPage} / {numPages}
        </span>

        {!isLast && (
          <Link
            to={nextPage}
            rel="next"
            className="ml-auto bg-black font-graphik font-bold leading-normal text-sm uppercase tracking-wide text-white hover:bg-white hover:text-black trans p-4 z-10"
          >
            Next Page →
          </Link>
        )}
      </PaginationHolder>
    </div>
  )
}

export default PaginationBlock

// <ul
// 	style={{
// 		display: "flex",
// 		flexWrap: "wrap",
// 		justifyContent: "space-between",
// 		alignItems: "center",
// 		listStyle: "none",
// 		padding: 0
// 	}}
// ></ul>
// {Array.from({ length: numPages }, (_, i) => (
// 	<li
// 		key={`pagination-number${i + 1}`}
// 		style={{
// 			margin: 0
// 		}}
// 	>
// 		<Link
// 			to={linkWord + `/${i === 0 ? "" : i + 1}`}
// 			style={{
// 				padding: `5px`,
// 				textDecoration: "none",
// 				color: i + 1 === currentPage ? "#ffffff" : "",
// 				background: i + 1 === currentPage ? "#007acc" : ""
// 			}}
// 		>
// 			{i + 1}
// 		</Link>
// 	</li>
// ))}
