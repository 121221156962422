import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import get from 'lodash/get'
import CardList from '../components/CardList'
import Card from '../components/Card'
import PaginationBlock from '../components/PaginationBlock'

class ArticlesIndex extends Component {
  render() {
    const posts = get(this, 'props.data.craft.entries')
    const { currentPage, numPages, totalCount } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? 'work/' : 'work/' + (currentPage - 1).toString()
    const nextPage = 'work/' + (currentPage + 1).toString()
    return (
      <Layout>
        <div className="container">
          <CardList>
            <div className="w-full flex px-4 mb-6">
              <h2>Title</h2>
              <h4>{totalCount}</h4>
            </div>
            {posts.map((entry, i) => (
              <Card
                key={i}
                mainLink={`/${entry.uri}`}
                title={entry.title}
                imageUrl={
                  !entry.primaryThumb.length
                    ? 'https://placehold.it/400x225'
                    : entry.primaryThumb[0].url +
                      '?sat=-100&w=400&h=225&fit=crop&fp-x=' +
                      entry.primaryThumb[0].focalPoint.x +
                      '&fp-y=' +
                      entry.primaryThumb[0].focalPoint.y +
                      '&fp-debug=false'
                }
              />
            ))}
          </CardList>
        </div>

        <PaginationBlock
          nextPage={nextPage}
          prevPage={prevPage}
          numPages={numPages}
          currentPage={currentPage}
          isFirst={isFirst}
          isLast={isLast}
          linkWord={'work'}
        />
      </Layout>
    )
  }
}

export default ArticlesIndex

export const workListQuery = graphql`
  query workListQuery($offset: Int!, $limit: Int!) {
    craft {
      entries(
        section: [work]
        limit: $limit
        offset: $offset
        orderBy: "postDate desc"
      ) {
        ... on Craft_Work {
          title
          uri
          slug
          primaryThumb {
            url
            focalPoint {
              x
              y
            }
          }
          postDate
        }
      }
    }
  }
`

// const posts = get(this, "props.data.craft.entries");
// <h4>{totalCount}</h4>
