/* global tw */
import React from "react";
import styled from "styled-components";

const List = styled.div`
	${tw`flex flex-wrap -mx-4 md:mb-8 md:px-0`};
`;

const CardList = props => {
	return <List>{props.children}</List>;
};

export default CardList;
