/* global tw */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

// const CardBlock = styled.div`
// 	${tw`w-full md:w-1/3 flex px-4 mb-6 group text-black trans`};
// `;

const CardBlock = styled.div`
  ${tw`w-full md:w-1/3 flex md:px-4 md:mb-6 group text-black trans border-b border-grey-lighter md:border-0`};
`

const CardBg = styled.div`
  ${tw`card bg-white trans w-full h-full`};
`

const SecondLine = styled.p`
  ${tw`text-xs leading-tight font-graphik uppercase font-black`};
`

const CatCrumb = styled.h3`
  ${tw`text-xs uppercase mb-1`};
`

const Card = ({
  title,
  mainLink,
  imageUrl,
  tinyImageUrl,
  lineTwo,
  catName,
  extraStuff,
  ...props
}) => {
  return (
    <CardBlock className="group trans">
      <CardBg className="group-hover:shadow-lg trans">
        <Link to={mainLink}>
          {imageUrl ? (
            <img
              src={imageUrl}
              alt={title}
              className={props.avatarStyle ? 'hidden md:block' : null}
            />
          ) : null}
        </Link>
        <div className="px-6 py-4 flex items-center w-full">
          {props.avatarStyle ? (
            <img
              className="block md:hidden w-16 h-16 rounded-full mr-4 ml-0"
              src={tinyImageUrl}
              alt=""
            />
          ) : null}
          <div className="sm:flex-grow">
            <CatCrumb>{catName}</CatCrumb>
            <Link to={mainLink}>
              <h2 className="text-2xl leading-none font-graphik uppercase font-black text-black group-hover:text-blue">
                {title}
              </h2>
            </Link>
            <SecondLine>{lineTwo}</SecondLine>
            {extraStuff}
            {props.children}
          </div>
        </div>
      </CardBg>
    </CardBlock>
  )
}
export default Card
